import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql"
import { graphql, Link, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import { ListServiceProvidersQuery } from "../../graphql-types"
import Layout from "../components/Layout"
import { getServiceProvidersFromQueryResult } from "../queries/serviceProviders"
import { ServiceProvider } from "../types"
import { Props } from "../utils/context"
import i18n from "../utils/i18n"

export const categoryQuery = graphql`
  query ListServiceProviders {
    prismic {
      allServiceproviders(first: 100) {
        edges {
          node {
            name
            description
            _meta {
              type
              lang
              uid
              id
            }
          }
        }
      }
    }
  }
`

function ServiceProviderItem(props: { serviceProvider: ServiceProvider }) {
  const { serviceProvider } = props

  return (
    <Link
      to={linkResolver(serviceProvider)}
      className="no-underline text-black"
    >
      <h2>{serviceProvider.name}</h2>
      <RichText render={serviceProvider.description} />
    </Link>
  )
}

export default function ServiceProviders({ pageContext }: Props) {
  const serviceProvidersQueryResult = useStaticQuery(
    categoryQuery
  ) as ListServiceProvidersQuery

  const serviceProviders = getServiceProvidersFromQueryResult(
    serviceProvidersQueryResult,
    pageContext.lang
  )

  return (
    <Layout lang={pageContext.lang}>
      <div className="m-4">
        <h1>{i18n[pageContext.lang].general.serviceProviders}</h1>

        <div className="mt-4 grid grid-cols-1 gap-x-4 gap-y-8 lg:grid-cols-2">
          {serviceProviders.map(serviceProvider => (
            <ServiceProviderItem
              key={`serviceprovider-${serviceProvider.uid}`}
              serviceProvider={serviceProvider}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}
